export const LAYOUT_CONTEXT_KEY = Symbol() as InjectionKey<{
    hero: Awaited<ReturnType<typeof useHero>>
}>

export const useLayoutContext = () => {
    const context = inject(LAYOUT_CONTEXT_KEY)

    if (!context) {
        throw new Error('No layout context provided')
    }

    return context
}
